import { FieldType, Form } from "@/interfaces/fields";
import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const TimeclockTableMetadata: TableHeader[] = [
  {
    value: "user.avatar_thumb_url",
    label: "",
    fieldComponent: TableFieldType.avatar,
    class: "tdt__headers__fieldImage",
    sortable: false
  },
  {
    value: "user.first_name/j/user.last_name",
    label: String(i18n.t("employee")),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: false
  },
  {
    value: "check_in",
    label: String(i18n.t("timeclock.time_in")),
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "check_out",
    label: String(i18n.t("timeclock.time_out")),
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "break_for",
    label: String(i18n.t("timeclock.break_time")),
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  }
];

export const breakForm: Form = {
  columns: 4,
  fields: [
    {
      title: "",
      fields: [
        {
          type: FieldType.time,
          label: "break_time_start",
          modelField: "break_in",
          validationRules: "",
          options: {
            placeholder: i18n.t("break_time_start")
          }
        },
        {
          type: FieldType.time,
          label: "break_time_end",
          modelField: "break_out",
          validationRules: "",
          options: {
            placeholder: i18n.t("break_time_end")
          }
        }
      ]
    }
  ]
};
