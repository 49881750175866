import { store } from "@/internal";
import { ActionSubheader, TableAction } from "helix-vue-components";

class TimeclockActionService {
  public getGeneralActions(): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: "add-time-clock"
          });
        }
      },
      {
        icon: "fal fa-search",
        otherComponent: {
          name: "TableSearchComponent"
        }
      }
    ];
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        action: (...arg: any[]) => {
          store.dispatch("RouterModule/go", {
            name: "time-clock-edit",
            params: {
              id: arg[0].id,
              currentModel: arg[0]
            }
          });
        }
      }
    ];
  }
}

export const timeclockActionService = new TimeclockActionService();
