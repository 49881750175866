import { HttpQuery } from "@/interfaces/httpQuery";
import { Timeclock } from "@/interfaces/timeclock";
import { pusherEvents } from "@/internal";
import { TimeclockTableMetadata } from "@/metadata/timeclock";
import { INITIAL_QUERY_STATE } from "@/services/http.service";
import { messagesService } from "@/services/messages.service";
import { timeclockActionService } from "@/services/timeclock.action.service";
import { timeclockService } from "@/services/timeclock.service";
import { usersService } from "@/services/user.service";
import { PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./TimeclockManager.template.vue";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
export default class TimeclockManagerComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  /**
   * Data to display in the table.
   */
  public timeclocks: Timeclock[] = [];

  /**
   * Pagination data.
   */
  public pagination: TablePagination | null = null;

  /**
   * Loading state.
   */
  public loading: boolean = true;

  /**
   * Headers data for the table
   */
  public headers: TableHeader[] = TimeclockTableMetadata;

  /**
   * General actions for subheader component.
   */
  public generalActions: ActionSubheader[] = timeclockActionService.getGeneralActions();

  /**
   * Row actions data for the table.
   */
  public rowActions: TableAction[] = timeclockActionService.getRowActions();

  /**
   * Data to set queries for each API request.
   */
  protected query: HttpQuery = {
    ...INITIAL_QUERY_STATE,
    sort: "-check_in"
  };

  /**
   * Table colors.
   */
  protected colors = ["white", "white", "white"];

  /**
   * Update requests on table page change event.
   * @param pagination: TablePagination
   */
  public onChangePage(pagination: TablePagination) {
    this.triggerSearch({
      ...this.query,
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage
    });
  }

  /**
   * Update requests on column name click to sort.
   * @param header: TableHader
   */
  public filter(header: TableHeader) {
    this.triggerSearch({
      ...this.query,
      sort: timeclockService.sortQuery(header)
    });
  }

  /**
   * Updates table data based on search component.
   * @param term: string
   */
  public async onSearch(term: string) {
    try {
      const users = await usersService.searchUsers(term);
      this.triggerSearch({
        ...INITIAL_QUERY_STATE,
        "q[user_id_is_in]":
          users && users.length ? users.map(user => user.id) : []
      });
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  /**
   * Takes action on serch bar close event.
   * @param update: boolean
   */
  public onClose(update: boolean) {
    if (update) {
      this.triggerSearch(INITIAL_QUERY_STATE);
    }
  }

  protected async mounted() {
    this.setPageNav({
      title: "time_clock_manager",
      rightActions: {
        generalActions: () => this.generalActions,
        onSearch: this.onSearch,
        onClose: this.onClose
      }
    });
    this.$changes.watch(pusherEvents.timeclockTouched, async () => {
      this.triggerSearch(this.triggerSearch());
    });
    this.triggerSearch(this.query);
  }

  private async triggerSearch(query?: HttpQuery) {
    this.loading = true;
    this.query = query || this.query;
    try {
      this.timeclocks = await timeclockService.get(this.query);
      this.pagination = await timeclockService.getPagination();
    } catch (e) {
      messagesService.renderErrorMessage(e);
    } finally {
      this.loading = false;
    }
  }
}
